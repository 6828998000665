.cp-info {
    /*background: rebeccapurple;*/
    text-align: center;
}
.cp-info .fbtn{
    display: inline-block;
    margin: 10px 5px 15px;
}
.cp-info a{
    font-weight: bold;
}
.cp-info a {
    margin: 20px auto;
}
.cp-info a.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    display: none;
}
