
.competition-details-header ul.actions {
    display: inline-block;
    list-style-type: none;
}

.competition-details-header ul.actions li {
    font-size: 30px;
}

.competition-details-header ul.actions li span.glyphicon {
    top: 10px;
}

.competition-details-header.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 200;
    padding: 10px 0;
    box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.3);
}


.competition-details-header.sticky .btn {
    font-size: 17px;
}

@media (max-width: 480px ) {
    .competition-details-header ul.actions {
        padding-left: 10px;
    }
}

@media only screen and (max-width: 768px) {

    .competition-details-header.sticky {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 200;
        padding: 10px 0;
        box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.3);
    }

}