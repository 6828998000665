.panel .slick-slide > div {
    height: 100%;
}
.panel .slick-slide > div > div {
    height: 100%;
    display: flex !important;
}
.panel .panel-img {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
}