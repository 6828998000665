.icons-list{
    list-style-type: none;
    padding-left: 0;
}

.icons-list li {
    display: inline-block;
    list-style-type: none;    
}
.i-custom{
    font-size: 24px !important;
    top: 5px;
    position: relative;
    line-height: 1.3;
}

.icons-list li.icon a {
    /*display: inline-block;*/
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 18px;
    height: 45px;
    line-height: 45px;
    margin: 5px;
    text-align: center;
    transition: all 0.2s ease 0s;
    width: 45px;
    background-color: #a9a8b3;

}

.icons-list li.icon a:hover,.icons-list li.icon a:focus{
    background-color: #bab9c2
}

.icon a > i{
    color: #fff;
    font-size: inherit;
}