@media (min-width: 1200px) {
    .container {
        width: 1080px;
    }
}

@media only screen and (min-width: 768px) {
    .navbar-header {
        display: none
    }

    .raceMenu ul li {
        width: 33.33%;
        display: inline-block;
        text-align: center;
    }

    .raceMenu .container {
        padding: 0
    }

    .mt-sm-15 {
        margin-top: 15px;
    }

    .mt-sm-20 {
        margin-top: 20px;
    }
    .mr-sm-10{
        margin-right: 10px;
    }
    .ml-sm-10{
        margin-left: 10px;
    }
}
p.v1{
    font-size: 18px;
    line-height: 24px !important;
}
a {
    color: #205173;
    text-decoration: none;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #205173;
    text-decoration: none;
    background-color: #eee;
}
p.justify{
    text-align: justify !important;
    line-height: 20px;
}
.b1 {
    background: #205173
}

a, a:hover, a:focus {
    text-decoration: none;
    outline: none
}

.c1, .navbar-custom .c1 {
    color: #205173;
}

.v1 {
    color: #205173;
}

.v2 {
    color: #3a3f42 !important
}

.v3 {
    color: #fafafa !important
}

a.v4:hover {
    color: #abb6c2 !important
}

.v4 {
    color: #8b9cab !important
}

.v5 {
    color: #333333
}

.v6 {
    color: #666666
}

.v7 {
    color: #999999
}

.v8 {
    color: #ffffff !important;
}

.v9 {
    color: #b8c7d4 !important
}

.v10 {
    color: #e85160 !important
}

.v11 {
    color: #444444 !important
}

.v12 {
    color: #9bb4c5;
}

.v12.folded {
    color: #fff;
}

.detail_description, .simpletext {
    font-size: 12px;
    line-height: 18px;
    word-wrap: break-word;
}

.race_description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
}

.race_description ul {
    list-style: none !important;
}

.information_box i {
    font-size: 0.8em;
    margin-right: 10px !important;
    display: inline-block
}

div.albums ul li {
    box-sizing: border-box;
}

div.photos ul li {
    padding: 10px;
    box-sizing: border-box;
}

div.photos ul.photo-props li {
    padding-left: 0;
    box-sizing: border-box;
}

.playerList .table-header-wrapper th, .raceRanking .table-header-wrapper th, .ks-table .table-header-wrapper th {
    background: #205173;
    color: #fff;
    border-color: #163E5D;
    font-weight: 400
}

label.control-label {
    font-weight: 400
}

.raceMenu ul {
    width: 100%;
}

.raceMenu ul li {
}

.navbar-nav {
    margin: 0
}

.raceMenu .collapse, .raceMenu .collapsing {
    padding: 0;
}

.raceRanking .react-bs-table-tool-bar, .ks-table .react-bs-table-tool-bar {
    margin: 0 0 5px 0;
}

.raceRanking .react-bs-table, .ks-table .react-bs-table {
    margin: 5px 0;
}

.raceRanking .react-bs-table-tool-bar .btn-success {
    color: #fff;
    background-color: #205173;
    border-color: #205173
}

.react-bs-table table td, .react-bs-table table th {
    white-space: normal !important;
}

.background-white {
    background-color: white;
}

h1, h2, h3 {
    margin-top: 0;
}

h3.title {
    font-size: 20px;
}

h4.title {
    font-size: 18px;
}

ul, ol {
    margin-bottom: 0
}

.full-width {
    width: 100%;
}

.block .v3-hover {
    color: #d3d3d3;
    text-decoration: none;
}

.block {
    padding: 1px 10px 0px 4px;
    float: right;
    background: transparent;
    text-align: left;
}

.shadowdark {
    -moz-box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
    -webkit-box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
    box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
}

.radius, input.inp, textarea {
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
}

.page-container {
    margin: 0;
    padding: 5px 20px 0;
    position: relative;
}

/*** NAVBAR ***/
.navbar-custom {
    border: none;
}

.navbar-header, .navbar-collapse {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #205173;
    background-color: #fff;
}

/* END NAVBAR*/

/** BUTTON START **/

.btn {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.btn:hover, .btn:focus, .btn.focus {
    color: #fff;
}

.subscribe-button {
    background: #205173;
    position: relative;
    border-radius: 0px;
}

.subscribe-button:hover {
    background-color: #1b405d;
    text-decoration: none
}

.subscribe-button.alert {
    background-color: #fbbc05;
}

.subscribe-button.alert:hover {
    background-color: #FFD10D;
}

.subscribe-button.success {
    background-color: #399504;
}

.subscribe-button.success:hover {
    background-color: #77bb44;
}

/*button.btn{
    border-radius: 0 !important;
}*/

.btn-xlg, .btn-group-xlg > .btn {
    padding: 15px 21px;
    font-size: 25px;
    line-height: 1.3333333;
}

.btn-wide, .btn-group-wide > .btn {
    padding: 10px 20px;
    font-size: 25px;
    line-height: 1.3333333;
}

.btn-medium, .btn-group-medium > .btn {    
    font-size: 25px;
    line-height: 1.3333333;
}

.btn-ks {
    color: #fff;
    background-color: #205173;
    border-color: #205173;
}

.btn-ks:hover, .btn-ks:focus {
    color: #fff;
    background-color: #1b405d;
    border-color: #1b405d;
}

.btn-ks[disabled]:hover {
    color: #fff;
    background-color: #c53419;
}

.btn-gray {
    color: #1b405d;
    background-color: #eee;
    border-color: #eee;
}

.btn-gray:hover, .btn-gray:focus {
    color: #1b405d;
    background-color: #cecece;
    border-color: #eee;
}

.btn-gray[disabled]:hover {
    color: #fff;
    background-color: #c53419;
}

.payment-method-btn {
    margin-bottom: 10px;
}


/** BUTTON END**/

/** BOX START **/
.box-header .title-wrapper {
    float: left;
    display: inline-block;
}

.box-header .actions {
    float: right;
    display: inline-block;
}

/** BOX END **/

/** ICONS */

i.glyphicon {
    margin-right: 3px;
}

/* SLICK SLIDER */
/* arrows */
.slick-prev:before, .slick-next:before {
    color: #1b405d !important;
}

.slick-slide {
    text-align: center;
}

.slick-slide::before {
    content: '';
    height: 100%;
    vertical-align: middle;
}

.slick-slide img {
    vertical-align: middle;
    display: inline-block;
}

.w100 {
    width: 100px
}

.w75 {
    width: 75px
}

.w50 {
    width: 50px
}

.w25 {
    width: 25px
}

@media only screen and (min-width: 320px) {
    .slick-track {
        height: 150px;
    }

    .comment-textarea {
        width: 85%
    }
}

@media only screen and (min-width: 480px) {
    .slick-track {
        height: 175px;
    }

    .comment-textarea {
        width: 90%
    }
}

@media only screen and (min-width: 768px) {
    .slick-track {
        height: 200px;
    }

    .comment-textarea {
        width: 95%
    }

    .prefix-input {
        width: 90%;
    }

    .subscription-total-wrapper .subscription-btn-wrapper button {
        float: left;
    }
}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1200px) {

}

/*END SLICK SLIDER*/


/** PHOTOS **/
label.photo-label {
    float: left;
    padding: 7px 8px 0 0;
}

label.checkbox-buy-photo {
    float: none;
}

label.ellipsis-label, .text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


@media only screen and (max-width: 768px) {
    .subscription-total-wrapper .totals {
        text-align: center;
    }

    .subscription-total-wrapper .subscription-btn-wrapper {
        text-align: center;
    }

    .prefix-input {
        width: 85%;
    }
}

@media only screen and (max-width: 480px) {
    .prefix-input {
        width: 75%;
    }
}


/** END ICONS **/

/** FORM **/
#subscription-form .datepicker__input {
    width: 100%;
    border-radius: 0px 4px 4px 0px;
}

.has-error .Select-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error {
    color: #a94442;
}

.has-success {
    color: #3c763d
}

/** END FORM **/

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #205173;
    outline: 0;
}

.bank-payment ul {
    list-style-type: none;
    padding-left: 0;
}

.light, h2.title, h3.title, h4.title {
    font-family: 'OpenSansLight', 'Arial', sans-serif;
    font-weight: normal;
}

.lightitalic {
    font-family: 'OpenSansLightItalic', 'Arial', sans-serif;
    font-weight: normal;
}

.regular {
    font-family: 'OpenSansRegular', 'Arial', sans-serif;
    font-weight: normal;
}

.italic {
    font-family: 'OpenSansItalic', 'Arial', sans-serif;
    font-weight: normal;
}

.semibold {
    font-family: 'OpenSansSemibold', 'Arial', sans-serif;
    font-weight: normal;
}

.semibolditalic {
    font-family: 'OpenSansSemiboldItalic', 'Arial', sans-serif;
    font-weight: normal;
}

.bold {
    font-family: 'OpenSansBold', 'Arial', sans-serif;
    font-weight: normal;
}

.bolditalic {
    font-family: 'OpenSansBoldItalic', 'Arial', sans-serif;
    font-weight: normal;
}

.extrabold {
    font-family: 'OpenSansExtrabold', 'Arial', sans-serif;
    font-weight: normal;
}

.extrabolditalic {
    font-family: 'OpenSansExtraboldItalic', 'Arial', sans-serif;
    font-weight: normal;
}

.graybutton {
    display: block;
    font-size: 12px;
    padding: 6px 8px;
    /* margin: 4px 0; */
    border: 1px solid #cccccc;
    background: #fdfdfd;
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    color: #666666;
    line-height: 18px;
    cursor: pointer;
}

.bluebutton {
    background: #205173;
    border: 1px solid #163E5D;
    color: #f3f3f3 !important;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    display: block;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 18px;
    text-shadow: 0 1px 2px #163E5D;
}

.graybutton i, .bluebutton i {
    line-height: 18px;
}

.graybutton:hover, .graybuttonhover, .graybutton.v1:hover {
    color: #333333 !important;
    border-color: #bbbbbb;
    text-decoration: none
}

.graybutton:active, .graybuttonhover:active, .graybutton.v1:active {
    color: #333333 !important;
    border-color: #bbbbbb;
    background: #fff
}

.bluebutton:hover {
    color: #ffffff;
    border-color: #163E5D;
    text-decoration: none
}

.graybutton i {
    background: #fdfdfd
}

.bluebutton i {
    background: #205173;
    color: #f3f3f3 !important;
}

.bluebutton:hover i {
    background: #28597d;
    color: #fff
}

button.bluebutton.floatleft, button.bluebutton.floatright, .searchuser form button.bluebutton, button.bluebutton.absolute, button.bluebutton.inlineblock {
    width: auto
}

.facebookbutton {
    display: block;
    font-size: 12px;
    padding: 6px 8px;
    border: 1px solid #2a4582;
    background: #3b5998;
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    box-shadow: 0 1px 2px #dddddd;
    -moz-box-shadow: 0 1px 2px #dddddd;
    -webkit-box-shadow: 0 1px 2px #dddddd;
    color: #ffffff;
    line-height: 17px;
    cursor: pointer;
    text-shadow: 0 1px 2px #2a4582;
}

.facebookbutton:hover {
    background: #4461a0;
    color: #ffffff;
}

.radius, .well, .panel, input.inp, textarea {
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
}

.radius-top {
    border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
}

.radius-top-right {
    border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    -webkit-border-radius: 0 2px 0 0;
}

.radius-top-left {
    border-radius: 2px 0 0 0;
    -moz-border-radius: 2px 0 0 0;
    -webkit-border-radius: 2px 0 0 0;
}

.radius-bottom, .left-big:not(.card) .whitebox, .radius-bottom .oEmbedBg {
    border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -webkit-border-radius: 0 0 2px 2px;
}

.radius-bottom-left {
    border-radius: 0 0 0 2px;
    -moz-border-radius: 0 0 0 2px;
    -webkit-border-radius: 0 0 0 2px;
}

.radius-bottom-right {
    border-radius: 0 0 2px 0;
    -moz-border-radius: 0 0 2px 0;
    -webkit-border-radius: 0 0 2px 0;
}

.radius-left {
    border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    -webkit-border-radius: 2px 0 0 2px;
}

.radius-right {
    border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -webkit-border-radius: 0 2px 2px 0;
}

.radius50 {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

.radiusnone {
    border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -webkit-border-radius: 0 0 0 0;
}

.shadowdark {
    -moz-box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
    -webkit-box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
    box-shadow: 0 1px 2px rgba(17, 48, 72, 0.6) !important;
}

.shadow666 {
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !important;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !important;
}

.shadow999 {
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
}

.shadowDDD, .selectlistbox, input#sportsearch:focus {
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16) !important;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16) !important;
}

.shadowAAA {
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.shadownone {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.shadowTransparent, #tooltip {
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.shadowTransparentBig {
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
}

.floatleft {
    display: block;
    float: left !important;
}

.floatright {
    display: block;
    float: right !important;
}

.floatnone {
    display: block;
    float: none !important;
}

.size10 {
    font-size: 10px !important;
}

.size11 {
    font-size: 11px !important;
    line-height: 16px !important;
}

.size12 {
    font-size: 12px !important;
    line-height: 16px;
}

.size14 {
    font-size: 14px !important;
}

.size15 {
    font-size: 15px !important;
}

.size16 {
    font-size: 16px !important;
}

.size18 {
    font-size: 18px !important;
}

.size20 {
    font-size: 20px !important;
}

.size21 {
    font-size: 21px !important;
}

.size22 {
    font-size: 22px !important;
}

.size24 {
    font-size: 24px !important;
}

.size30 {
    font-size: 30px !important;
}

.size36 {
    font-size: 36px !important;
}

.size40 {
    font-size: 40px !important;
}

.size46 {
    font-size: 46px !important;
}

.size50 {
    font-size: 50px !important;
}

.nomargin {
    margin: 0 !important
}

.padding40 {
    padding: 40px !important;
}

.padding20 {
    padding: 20px !important;
}

.padding10 {
    padding: 10px !important;
}

.padding8 {
    padding: 8px !important;
}

.paddingright8 {
    padding-right: 8px !important;
}

.paddingleft8 {
    padding-left: 8px !important;
}

.paddingleft20 {
    padding-left: 20px !important;
}

.paddingtop8 {
    padding-top: 8px !important;
}

.paddingbottom8 {
    padding-bottom: 8px !important;
}

.paddingbottom15 {
    padding-bottom: 15px !important;
}

.nopadding {
    padding: 0 !important;
}

.nopaddingbottom {
    padding-bottom: 0 !important;
}

.nopaddingtop {
    padding-top: 0 !important;
}

.nomarginright {
    margin-right: 0 !important
}

.nomargintop {
    margin-top: 0 !important;
}

.nomarginbottom {
    margin-bottom: 0 !important;
}

.marginright8 {
    margin-right: 8px !important;
}

.marginright10 {
    margin-right: 10px !important;
}

.marginleft8 {
    margin-left: 8px !important;
}

.marginleft10 {
    margin-left: 10px !important;
}

.marginleft20 {
    margin-left: 20px !important;
}

.margintop8 {
    margin-top: 8px !important;
}

.margintop10 {
    margin-top: 10px;
}

.margintop20 {
    margin-top: 20px;
}

.marginbottom8 {
    margin-bottom: 8px !important;
}

.marginbottom15 {
    margin-bottom: 15px !important;
}

.marginbottom4 {
    margin-bottom: 4px !important;
}

.marginbottom30 {
    margin-bottom: 30px !important;
}

.margin8 {
    margin: 8px;
}

.margin10 {
    margin: 10px;
}

.noborder {
    border: 0 !important
}

.noborderright {
    border-right: 0px !important
}

.break-word {
    word-wrap: break-word;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    z-index: 1;
}

.no-overflow {
    overflow: hidden;
}

.lheight32 {
    line-height: 32px !important;
}

.lheight20 {
    line-height: 20px !important;
}

.lheight18 {
    line-height: 18px !important;
}

.width100 {
    width: 100px
}

.width10 {
    width: 10% !important;
}


.mw-200 {
    min-width: 500px
}

.width-20 {
    width: 20% !important
}

.width-30 {
    width: 30% !important
}

.width-40 {
    width: 40% !important
}

.payment-checkbox label input {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.medium-checkbox label input {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

#notifybox {
    width: 980px;
    display: block;
    text-align: center;
    position: relative;
    margin: 0 auto;
}

.notifymessage {
    float: left;
    padding: 10px 14px;
    margin-top: 16px;
    font-size: 12px;
    display: none;
    color: #fcfcfc;
    text-shadow: 0 1px 1px #ac0012;
    position: fixed;
    z-index: 99999
}

.notifymessage.error {
    background: #e85160;
    border: 1px solid #ac0012;
}

.notifymessage.success {
    background: #77bb44;
    border: 1px solid #399504;
    text-shadow: 0 1px 2px #2a6c05 !important;
}

.creation_msg {
    display: block;
    padding: 10px 14px;
    font-size: 12px;
    color: #fcfcfc;
    background: #77bb44;
    border: 1px solid #399504;
    text-shadow: 0 1px 2px #2a6c05 !important;
}

.notifymessage span {
    cursor: pointer;
    color: #fff;
}

.notifyicon {
    color: #9bb4c5;
    text-shadow: none;
}

.unfold {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    background: transparent;
}

.horiz-p5 {
    font-size: 16px;
    padding: 0 4px;
}

input[type=checkbox].checkbox-medium {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    padding: 10px;
    transform: scale(1.5);
}

.marginleft8 {
    margin-left: 8px;
}

.inlineblock {
    display: inline-block;
}

#footer {
    padding: 14px 10px 30px 10px;
    background: transparent;
    line-height: 30px;
    margin: 0 auto 10px;
    font-size: 11px;
}

.footerImg {
    height: 16px;
    margin-top: 7px;
}

.language-selector {
    display: inline;
}

.language-selector span:not(:last-child):after {
    content: ' | ';
}

.order-form-spinner {
    top: 20px;
    position: relative;
}


@media only screen and (max-width: 480px) {
    .width10 {
        width: 15% !important;
    }

    .raceRanking td.full-name {
        white-space: normal;
    }

    .order-form-spinner {
        padding: 20px !important;
    }
}


@media only screen and (max-width: 359px) {
    .xxs-mt-10 {
        margin-top: 15px
    }
}


ul.nav > li > a > span.counter {
    width: 30px;
    text-align: center;
    display: inline-block;
    line-height: 30px;
    background: #205173;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
}


ul.nav > li.disabled > a > span.counter {
    width: 30px;
    text-align: center;
    display: inline-block;
    line-height: 30px;
    background: #eee;
    color: #666;
    border-radius: 50%;
    margin-right: 10px;
}

.fade {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}

.container-space {
    margin: 20px -20px 10px -20px;
    height: 20px;
    background-color: #e6e6e6;
}

/*.form-group .wrapper > span.help-block{
    margin-top:0;
}*/

/** comments **/
.comm-separator {
    padding-right: 5px;
    padding-left: 5px;
}


ul.comments-list {
    list-style-type: none;
    margin-top: 1.5rem;
    padding-left: 0;
}

li.comm-item .comm-content {
    position: relative;
    margin-bottom: 1.5rem;
    transition: all .2s ease-in-out;
}

li.comm-item .comm-content .avatar {
    float: left;
    margin-right: 1rem;
}

li.comm-item .comm-content .avatar img {
    border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    width: 40px;

    margin-right: 5px;
}

li.comm-item .comm-content .comm-body {
    overflow: hidden;
}

li.comm-item .comm-content .comm-body .comm-header {
    line-height: 1;
    font-size: 13px;
    padding-right: 46px;
    margin-bottom: 3px;
}

li.comm-item .comm-content .comm-body .comm-text {
    position: relative;
    overflow: hidden;
}

li.comm-item .comm-content .comm-body .comm-footer .comm-options {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li.comm-item .comm-content .comm-body .comm-footer .comm-options li {
    float: left;
    font-size: 12px;
}

li.comm-item .comm-content .comm-body .comm-footer .comm-options li a.comm-delete {
    color: #939393;
}

.list-flag {
    float: left;
    position: relative;
    height: 10px;
    width: 14px;
    display: block;
    margin-right: 6px;
    top: 3px;
    background-image: url(/assets/img/flag-sprite.png);
}

.panel-item {
    border-left: 6px solid #e6e6e6;
}

.panel-item > .title {
    color: #205173;
    font-weight: bold;
}

.row-white-border {
    margin: 20px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 10px 0;
}

.text404 {
    padding: 20px;
    text-shadow: 1px 1px;
    font-size: 60px;
}

.pointer {
    cursor: pointer;
}

.linkable-list-card:hover {
    box-shadow: 1px 4px 10px grey;
    -webkit-transition: box-shadow .2s ease-in;
}

.flag-ad {
    background-position: 0px 0px;
}

.flag-ae {
    background-position: 0px -13px;
}

.flag-af {
    background-position: 0px -26px;
}

.flag-ag {
    background-position: 0px -39px;
}

.flag-ai {
    background-position: 0px -52px;
}

.flag-al {
    background-position: 0px -65px;
}

.flag-am {
    background-position: 0px -78px;
}

.flag-an {
    background-position: 0px -91px;
}

.flag-ao {
    background-position: 0px -104px;
}

.flag-aq {
    background-position: 0px -117px;
}

.flag-ar {
    background-position: 0px -130px;
}

.flag-as {
    background-position: 0px -143px;
}

.flag-at {
    background-position: 0px -156px;
}

.flag-au {
    background-position: 0px -169px;
}

.flag-aw {
    background-position: 0px -182px;
}

.flag-ax {
    background-position: 0px -195px;
}

.flag-az {
    background-position: 0px -208px;
}

.flag-ba {
    background-position: 0px -221px;
}

.flag-bb {
    background-position: 0px -234px;
}

.flag-bd {
    background-position: 0px -247px;
}

.flag-be {
    background-position: 0px -260px;
}

.flag-bf {
    background-position: 0px -273px;
}

.flag-bg {
    background-position: 0px -286px;
}

.flag-bh {
    background-position: 0px -299px;
}

.flag-bi {
    background-position: 0px -312px;
}

.flag-bj {
    background-position: 0px -325px;
}

.flag-bl {
    background-position: 0px -338px;
}

.flag-bm {
    background-position: 0px -351px;
}

.flag-bn {
    background-position: 0px -364px;
}

.flag-bo {
    background-position: 0px -377px;
}

.flag-br {
    background-position: 0px -390px;
}

.flag-bs {
    background-position: 0px -403px;
}

.flag-bt {
    background-position: 0px -416px;
}

.flag-bv {
    background: url(/assets/img/flag/iso/bv.png) no-repeat top left;
}

.flag-bw {
    background-position: 0px -429px;
}

.flag-by {
    background-position: 0px -442px;
}

.flag-bz {
    background-position: 0px -455px;
}

.flag-ca {
    background-position: 0px -468px;
}

.flag-cc {
    background: url(/assets/img/flag/iso/cc.png) no-repeat top left;
}

.flag-cd {
    background-position: 0px -481px;
}

.flag-cf {
    background-position: 0px -494px;
}

.flag-cg {
    background-position: 0px -507px;
}

.flag-ch {
    background-position: 0px -520px;
}

.flag-ci {
    background-position: 0px -533px;
}

.flag-ck {
    background: url(/assets/img/flag/iso/ck.png) no-repeat top left;
}

.flag-cl {
    background-position: 0px -546px;
}

.flag-cm {
    background-position: 0px -559px;
}

.flag-cn {
    background-position: 0px -572px;
}

.flag-co {
    background-position: 0px -585px;
}

.flag-cr {
    background-position: 0px -658px;
}

.flag-cu {
    background-position: 0px -611px;
}

.flag-cv {
    background-position: 0px -624px;
}

.flag-cy {
    background-position: 0px -637px;
}

.flag-cx {
    background: url(/assets/img/flag/iso/cx.png) no-repeat top left;
}

.flag-cz {
    background-position: 0px -650px;
}

.flag-de {
    background-position: 0px -663px;
}

.flag-dj {
    background-position: 0px -676px;
}

.flag-dk {
    background-position: 0px -689px;
}

.flag-dm {
    background-position: 0px -702px;
}

.flag-do {
    background-position: 0px -715px;
}

.flag-dz {
    background-position: 0px -728px;
}

.flag-ec {
    background-position: 0px -741px;
}

.flag-ee {
    background-position: 0px -754px;
}

.flag-eg {
    background-position: 0px -767px;
}

.flag-eh {
    background-position: 0px -780px;
}

.flag-er {
    background-position: 0px -793px;
}

.flag-es {
    background-position: 0px -806px;
}

.flag-et {
    background-position: 0px -819px;
}

.flag-fi {
    background-position: 0px -832px;
}

.flag-fj {
    background-position: 0px -845px;
}

.flag-fk {
    background-position: 0px -858px;
}

.flag-fm {
    background-position: 0px -871px;
}

.flag-fo {
    background-position: 0px -884px;
}

.flag-fr {
    background-position: 0px -897px;
}

.flag-ga {
    background-position: 0px -910px;
}

.flag-gb,
.flag-uk,
.flag-en {
    background-position: 0px -923px;
}

.flag-gd {
    background-position: 0px -936px;
}

.flag-ge {
    background-position: 0px -949px;
}

.flag-gf {
    background: url(/assets/img/flag/iso/gf.png) no-repeat top left;
}

.flag-gi {
    background: url(/assets/img/flag/iso/gi.png) no-repeat top left;
}

.flag-gg {
    background-position: 0px -962px;
}

.flag-gh {
    background-position: 0px -975px;
}

.flag-gl {
    background-position: 0px -988px;
}

.flag-gm {
    background-position: 0px -1001px;
}

.flag-gn {
    background-position: 0px -1014px;
}

.flag-gq {
    background-position: 0px -1027px;
}

.flag-gp {
    background: url(/assets/img/flag/iso/gp.png) no-repeat top left;
}

.flag-gr {
    background-position: 0px -1040px;
}

.flag-gs {
    background-position: 0px -1053px;
}

.flag-gt {
    background-position: 0px -1066px;
}

.flag-gu {
    background-position: 0px -1079px;
}

.flag-gw {
    background-position: 0px -1092px;
}

.flag-gy {
    background-position: 0px -1105px;
}

.flag-hk {
    background-position: 0px -1118px;
}

.flag-hm {
    background: url(/assets/img/flag/iso/hm.png) no-repeat top left;
}

.flag-hn {
    background-position: 0px -1131px;
}

.flag-hr {
    background-position: 0px -1144px;
}

.flag-ht {
    background-position: 0px -1157px;
}

.flag-hu {
    background-position: 0px -1170px;
}

.flag-id {
    background-position: 0px -1183px;
}

.flag-ie {
    background-position: 0px -1196px;
}

.flag-io {
    background: url(/assets/img/flag/iso/io.png) no-repeat top left;
}

.flag-il {
    background-position: 0px -1209px;
}

.flag-im {
    background-position: 0px -1222px;
}

.flag-in {
    background-position: 0px -1235px;
}

.flag-iq {
    background-position: 0px -1248px;
}

.flag-ir {
    background-position: 0px -1261px;
}

.flag-is {
    background-position: 0px -1274px;
}

.flag-it {
    background-position: 0px -1287px;
}

.flag-je {
    background-position: 0px -1300px;
}

.flag-jm {
    background-position: 0px -1313px;
}

.flag-jo {
    background-position: 0px -1326px;
}

.flag-jp {
    background-position: 0px -1339px;
}

.flag-ke {
    background-position: 0px -1352px;
}

.flag-kg {
    background-position: 0px -1365px;
}

.flag-kh {
    background-position: 0px -1378px;
}

.flag-ki {
    background-position: 0px -1391px;
}

.flag-km {
    background-position: 0px -1404px;
}

.flag-kn {
    background-position: 0px -1417px;
}

.flag-kp {
    background-position: 0px -1430px;
}

.flag-kr {
    background-position: 0px -1443px;
}

.flag-kw {
    background-position: 0px -1456px;
}

.flag-ky {
    background-position: 0px -1469px;
}

.flag-kz {
    background-position: 0px -1482px;
}

.flag-la {
    background-position: 0px -1495px;
}

.flag-lb {
    background-position: 0px -1508px;
}

.flag-lc {
    background-position: 0px -1521px;
}

.flag-li {
    background-position: 0px -1534px;
}

.flag-lk {
    background-position: 0px -1547px;
}

.flag-lr {
    background-position: 0px -1560px;
}

.flag-ls {
    background-position: 0px -1573px;
}

.flag-lt {
    background-position: 0px -1586px;
}

.flag-lu {
    background-position: 0px -1599px;
}

.flag-lv {
    background-position: 0px -1612px;
}

.flag-ly {
    background-position: 0px -1625px;
}

.flag-ma {
    background-position: 0px -1638px;
}

.flag-mc {
    background-position: 0px -1651px;
}

.flag-md {
    background-position: 0px -1664px;
}

.flag-me {
    background-position: 0px -1677px;
}

.flag-mg {
    background-position: 0px -1750px;
}

.flag-mh {
    background-position: 0px -1703px;
}

.flag-mk {
    background-position: 0px -1716px;
}

.flag-ml {
    background-position: 0px -1729px;
}

.flag-mm {
    background-position: 0px -1742px;
}

.flag-mn {
    background-position: 0px -1755px;
}

.flag-mo {
    background-position: 0px -1768px;
}

.flag-mp {
    background-position: 0px -1781px;
}

.flag-mr {
    background-position: 0px -1794px;
}

.flag-ms {
    background-position: 0px -1807px;
}

.flag-mt {
    background-position: 0px -1820px;
}

.flag-mu {
    background-position: 0px -1833px;
}

.flag-mv {
    background-position: 0px -1846px;
}

.flag-mq {
    background: url(/assets/img/flag/iso/mq.png) no-repeat top left;
}

.flag-mw {
    background-position: 0px -1859px;
}

.flag-mx {
    background-position: 0px -1872px;
}

.flag-my {
    background-position: 0px -1885px;
}

.flag-mz {
    background-position: 0px -1898px;
}

.flag-na {
    background-position: 0px -1911px;
}

.flag-nc {
    background: url(/assets/img/flag/iso/nc.png) no-repeat top left;
}

.flag-ne {
    background-position: 0px -1924px;
}

.flag-nf {
    background-position: 0px -1937px;
}

.flag-ng {
    background-position: 0px -1950px;
}

.flag-ni {
    background-position: 0px -1963px;
}

.flag-nl {
    background-position: 0px -1976px;
}

.flag-no {
    background-position: 0px -1989px;
}

.flag-np {
    background-position: 0px -2002px;
}

.flag-nu {
    background: url(/assets/img/flag/iso/nu.png) no-repeat top left;
}

.flag-nr {
    background-position: 0px -2015px;
}

.flag-nz {
    background-position: 0px -2028px;
}

.flag-om {
    background-position: 0px -2041px;
}

.flag-pa {
    background-position: 0px -2054px;
}

.flag-pe {
    background-position: 0px -2067px;
}

.flag-pf {
    background: url(/assets/img/flag/iso/pf.png) no-repeat top left;
}

.flag-pg {
    background-position: 0px -2080px;
}

.flag-ph {
    background-position: 0px -2093px;
}

.flag-pk {
    background-position: 0px -2106px;
}

.flag-pl {
    background-position: 0px -2119px;
}

.flag-pn {
    background-position: 0px -2132px;
}

.flag-pr {
    background-position: 0px -2145px;
}

.flag-ps {
    background-position: 0px -2158px;
}

.flag-pt {
    background-position: 0px -2171px;
}

.flag-pw {
    background-position: 0px -2184px;
}

.flag-py {
    background-position: 0px -2197px;
}

.flag-qa {
    background-position: 0px -2210px;
}

.flag-re {
    background: url(/assets/img/flag/iso/re.png) no-repeat top left;
}

.flag-ro {
    background-position: 0px -2223px;
}

.flag-rs {
    background-position: 0px -2236px;
}

.flag-ru {
    background-position: 0px -2249px;
}

.flag-rw {
    background-position: 0px -2262px;
}

.flag-sa {
    background-position: 0px -2275px;
}

.flag-sb {
    background-position: 0px -2288px;
}

.flag-sc {
    background-position: 0px -2301px;
}

.flag-sd {
    background-position: 0px -2314px;
}

.flag-se {
    background-position: 0px -2327px;
}

.flag-sj {
    background: url(/assets/img/flag/iso/sj.png) no-repeat top left;
}

.flag-sg {
    background-position: 0px -2340px;
}

.flag-sh {
    background-position: 0px -2353px;
}

.flag-si {
    background-position: 0px -2366px;
}

.flag-sk {
    background-position: 0px -2379px;
}

.flag-sl {
    background-position: 0px -2392px;
}

.flag-sm {
    background-position: 0px -2405px;
}

.flag-sn {
    background-position: 0px -2418px;
}

.flag-so {
    background-position: 0px -2431px;
}

.flag-sr {
    background-position: 0px -2444px;
}

.flag-st {
    background-position: 0px -2457px;
}

.flag-sv {
    background-position: 0px -2470px;
}

.flag-sy {
    background-position: 0px -2483px;
}

.flag-sz {
    background-position: 0px -2496px;
}

.flag-tc {
    background-position: 0px -2509px;
}

.flag-td {
    background-position: 0px -2522px;
}

.flag-tf {
    background: url(/assets/img/flag/iso/tf.png) no-repeat top left;
}

.flag-tg {
    background-position: 0px -2535px;
}

.flag-th {
    background-position: 0px -2548px;
}

.flag-tj {
    background-position: 0px -2561px;
}

.flag-tk {
    background: url(/assets/img/flag/iso/tk.png) no-repeat top left;
}

.flag-tl {
    background-position: 0px -2574px;
}

.flag-tm {
    background-position: 0px -2587px;
}

.flag-tn {
    background-position: 0px -2600px;
}

.flag-to {
    background-position: 0px -2613px;
}

.flag-tr {
    background-position: 0px -2626px;
}

.flag-tt {
    background-position: 0px -2639px;
}

.flag-tv {
    background-position: 0px -2652px;
}

.flag-tw {
    background-position: 0px -2665px;
}

.flag-tz {
    background-position: 0px -2678px;
}

.flag-ua {
    background-position: 0px -2691px;
}

.flag-ug {
    background-position: 0px -2704px;
}

.flag-us {
    background-position: 0px -2717px;
}

.flag-uy {
    background-position: 0px -2730px;
}

.flag-uz {
    background-position: 0px -2743px;
}

.flag-va {
    background: url(/assets/img/flag/iso/va.png) no-repeat top left;
}

.flag-vc {
    background-position: 0px -2756px;
}

.flag-ve {
    background-position: 0px -2769px;
}

.flag-vg {
    background-position: 0px -2782px;
}

.flag-vi {
    background-position: 0px -2795px;
}

.flag-vn {
    background-position: 0px -2808px;
}

.flag-vu {
    background-position: 0px -2821px;
}

.flag-wf {
    background: url(/assets/img/flag/iso/wf.png) no-repeat top left;
}

.flag-ws {
    background-position: 0px -2834px;
}

.flag-ye {
    background-position: 0px -2847px;
}

.flag-yt {
    background: url(/assets/img/flag/iso/yt.png) no-repeat top left;
}

.flag-za {
    background-position: 0px -2860px;
}

.flag-zm {
    background-position: 0px -2873px;
}

.flag-zw {
    background-position: 0px -2886px;
}

.flag-we {
    background-position: 0px -2899px;
}

.flag-ct {
    background-position: 0px -2912px;
}

/**
Pull push media queries
 */
@media (max-width: 767px) {
    /** Pull left xs **/
    .pull-left-xs {
        float: left;
    }

    /** Pull right xs **/
    .pull-right-xs {
        float: right;
    }
}

@media (min-width: 768px) {
    /** Pull left sm **/
    .pull-left-sm {
        float: left !important;;
    }

    /** Pull right sm **/
    .pull-right-sm {
        float: right !important;
    }
}

@media (min-width: 992px) {
    /** Pull left **/
    .pull-left-md {
        float: left;
    }

    /** Pull right **/
    .pull-right-md {
        float: right;
    }
}

@media (min-width: 1200px) {
    /** Pull left **/
    .pull-left-lg {
        float: left;
    }

    /** Pull right **/
    .pull-right-lg {
        float: right;
    }
}

.gly-spin {
    -webkit-animation: spin 2s infinite linear;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(359deg);
    }
}

.loading-container{
    text-align: center;
    font-size: 40px;
    position: relative !important;
}

#competition-gallery .loader{
    position: relative;
    height: 60px;
}


.share-title {
    font-weight: bold;
    text-transform: uppercase;
}

.map-directions {
    margin-top: 40px;
}

.map-directions-location,
.map-directions-cname{
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.map-directions-icons {
    text-align: center;
    line-height: 40px;
    font-size: 1.5em;
    min-height: 80px;
    /*margin-top: 15px;*/
}

.map-directions-icons a {
    padding: 15px;
}

.min-height-60{
    min-height: 60px;
}

.min-height-100{
    min-height: 100px;
}

.modal-full{
    width: unset !important;
    max-width: calc(100% - 30px);
    display: flex !important;
    margin: auto;
}
.modal-full .modal-dialog{
    width: unset !important;
    display: flex !important;
    align-self: center;

}
.modal-full-body{
    height: unset !important;
    max-height: calc(100vh - 120px);
    overflow: hidden !important;
    display: flex;
}
.modal-full .modal-footer {
    display: flex;
}
.modal-full .modal-arrow {
    flex: 1;
    cursor: pointer;
    user-select: none;
}

.modal-full .modal-arrow:first-child {
    flex: 1;
    text-align: left;
}

.gallery.pagination li:not(.disabled) {
    cursor: pointer !important;
}

.gallery.pagination > li:first-child,
.gallery.pagination > li:first-child a {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.gallery.pagination > li:last-child,
.gallery.pagination > li:last-child a {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.gallery.pagination li{
    padding: 0px 0px !important;
}

/** standing **/

.standings ul.list-inline a.graybutton{
    padding:10px 15px;
    font-size: 15px;
}

/** end standing **/