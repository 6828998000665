/*** BUTTONS ***/

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

.btn {
  border-radius: 0;
}

.btn.small {
  padding: 0 8px;
}

.btn.ks-btn-outline {
  border-color: #205173;
  color: #205173;
  background: 0 0;
}
.btn.ks-btn-outline.active,
.btn.ks-btn-outline:hover,
.btn.ks-btn-outline:focus {
  border-color: #205173;
  color: #fff;
  background-color: #205173;
}

/*** END BUTTONS **/

.oh {
  overflow: hidden;
}

.text-large {
  font-size: 24px !important;
}

a {
  color: #205173;
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #567b95;
}

.v-pad {
  padding-top: 10px;
  padding-bottom: 10px;
}

/**ICON**/

.icon-spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/*** ICON ***/

/*** FORM */
.form-control {
  border-radius: 0;
}

.Select-control {
  border-radius: 0;
}

form .inline-action {
  margin-top: 28px;
}

.row {
  padding: 0;
}

.margin-center {
  margin: 0 auto;
}
/*** END FORM ***/

.ks-label,
form .ks-label {
  color: #205173;
  font-size: 12px;
  display: block;
  width: inherit;
}

.ks-sport-item {
  font-size: 14px;
  background: #ffffff;
  /*border: 1px solid #e6e6e6;*/
  display: block;
  float: left;
  margin: 0 0 2px 0;
  padding: 10px 6px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 12px;
  width: 100%;
}

.form-group .Select-control {
  background: #f6f6f6 !important;
}

.list-item-container {
  line-height: 15px;
  background-color: #fff;
  margin-right: 0;
  margin-bottom: 5px;
  padding: 0px;
  display: block;
}

.list-item-container .list-item-thumb {
  float: left;
  padding: 0 5px 0 0;
}

.list-item-container .list-item-thumb img {
  display: block;
  max-width: 100%;
  height: auto;
}

.list-item-container .list-item-content {
  padding-top: 5px;
}

.list-item-container .list-item-content .list-item-title {
  text-align: left;
  margin-left: 105px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-flag {
  float: left;
  position: relative;
  height: 10px;
  width: 14px;
  display: block;
  margin-right: 6px;
  top: 3px;
  background-image: url(/assets/img/flag-sprite.png);
}

.react-loader {
  position: relative;
}

.alert {
  float: none;
  position: relative;
  text-shadow: none;
  font-size: 14px;
  display: block;
  z-index: 0;
}

.list-title {
  font-weight: normal;
  font-family: "OpenSansLight", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #205173;
  text-align: center;
}

.pagination li,
.pagination li > a {
  padding: 10px 17px;
  background: none;
  display: inline-block;
  margin-bottom: 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 0;
}

.pagination li > a {
  color: #205173;
}

.pagination li.active > a {
  background-color: #205173;
  color: #fff;
}

.payment-method-btn.active {
  font-weight: bold;
}

.img-btn {
  position: relative;
  display: block;
  width: 100%;
}

.img-btn:hover .img-btn-background {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  -ms-transform: scale(1.12);
  -o-transform: scale(1.12);
  transform: scale(1.12);
}

.img-btn:hover .img-btn-color:after,
.img-btn:focus .img-btn-color:after,
.img-btn:active .img-btn-color:after {
  opacity: 0.4;
  background-color: #282c35;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-radius: 3px;
}

.img-btn .img-btn-wrapper {
  overflow: hidden;
  position: relative;
}

.img-btn .img-btn-color {
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.img-btn .img-btn-background {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.35s;
  -moz-transition: -moz-transform 0.35s;
  transition: transform 0.35s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 220px;
  background-color: #eff2f5;
}

.small .img-btn .img-btn-background {
  height: 150px;
}

.img-btn .img-events-platform {
  background-image: url("/assets/img/events-platform.jpg");
}

.img-btn .img-register-now {
  background-image: url("/assets/img/register-now.jpg");
}

.img-btn .img-report-competition {
  background-image: url("/assets/img/page/orgcomp.jpg");
}

.img-btn .img-btn-text-wrapper {
  position: absolute;
  top: 50%;
  margin-top: -17.5px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.35s;
  -moz-transition: -moz-transform 0.35s;
  transition: transform 0.35s;
  width: 100%;
  text-align: center;
}

.small .img-btn .img-btn-title {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.img-btn .img-btn-title {
  color: #fff;
  font-size: 25px;
  line-height: 35px;
}

.m-10 {
  margin: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-25 {
  margin-top: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.p-10 {
  padding: 10px !important;
}

.p-25 {
  padding: 25px;
}

@media (max-width: 767px) {
  .pagination li {
    padding: 0px 4px;
    background: none;
    display: inline-block;
  }
}

/** RESET mobile style**/
.title {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: left;
}

.search-page .search-wrapper {
  overflow: hidden;
  min-height: 500px;
}

.organization-privacy {
  padding: 0px 20px 20px;
}

.organization-privacy h4 {
  color: #205173 !important;
  margin-top: 20px;
}

.organization-privacy ul.list-main {
  list-style-type: decimal;
}

.organization-privacy ul.list-secondary {
  list-style-type: disc;
}

.slider-container {
  -webkit-transition: height 0.5s ease-in;
  -moz-transition: height 0.5s ease-in;
  transition: height 0.5s ease-in;
  height: 90vw;

  max-height: 500px;
  position: relative;
  /*background-color: #fff;*/
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.slider-container .slider-image-container {
  -webkit-transition: height 0.5s ease-in;
  -moz-transition: height 0.5s ease-in;
  transition: height 0.5s ease-in;
  position: relative;
  /* background-color: #fff; */
  width: 100%;
  height: 36vw;
  max-height: 500px;
}

.slider-container .slider-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.slider-container .slider-overlay {
  margin: 0 55px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

  z-index: 2;
  position: relative;
}

.slider-overlay .overlay-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.white-container {
  padding: 20px;
  background-color: #fff;
}

.main-content {
  margin: 0 auto;
  max-width: 1080px;
}

.main-content .row {
  overflow: hidden;
}

.search-container {
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0.9) 0%, white 25%);
  background: linear-gradient(rgba(255, 255, 255, 0.9) 0%, white 25%);
  overflow: visible;
  padding: 10px;
  margin-top: 10px;
}

.search-container h3 {
  text-align: center;
}

.search-page .map-container {
  min-height: 200px;
}

.search-page .filters-wrapper {
  margin-top: 15px;
}

.search-page .search-wrapper {
  margin-top: 15px;
}

.search-page .filters-wrapper-form {
  background-color: #fff;
  padding: 10px;
}

.search-page .search-tag-wrapper {
  float: left;
  font-size: 13px;
  line-height: 18px;
}

.search-page .search-tag {
  display: block;
  background-color: #fff;
  background-color: transparent;
  color: #000;
  border-color: #d2d6df;
  border-width: 1px;
  border-style: solid;
  text-transform: capitalize;

  margin-right: 10px;
  padding: 6px 10px;
}

.inline-block {
  display: inline-block;
}

/** CARD **/

.card {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.2s cubic-bezier(0, 0.2, 0.4, 1);
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  display: block;
  border: none;
  border-radius: 0;
  margin: 0;
}

.card a.card-link-wrapper {
  display: block;
}

.card .card-header {
  position: relative;
  line-height: 0;
}

.card .card-header:before {
  display: block;
  content: "\00A0";
  width: 100%;
  padding-top: 50%;
}

.card.squared .card-header:before {
  display: block;
  content: "\00A0";
  width: 100%;
  padding-top: 100%;
}

.card .card-image {
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  transition: background-color 0.5s;
  overflow: hidden;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: #bcc5cc;*/
}

.card .card-image > img {
  max-width: 100%;
  max-height: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.squared-card .card-header-label,
.card .card-header-label {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.92);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  color: #282c35;
  box-sizing: border-box;
  /*padding: 2px 10px;*/
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 10px;
}

.card .card-content {
  padding: 5px 15px;
}

.squared-card .card-content {
  padding: 5px 15px;
  min-height: 140px;
}

.card .card-content .card-title {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #282c35;
  font-size: 15px;
  line-height: 19px;
  max-height: 2.5em;
  letter-spacing: 0;
  padding: 0;
  height: 2.5em;
}

.card .card-date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #45494e;
  font-size: 12px;
  line-height: 18px;
  height: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 0 0;
}

.squared-card .card-location,
.card .card-location {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #666a73;
  font-size: 12px;
  line-height: 18px;
  height: 2em;
  padding: 5px 0 0;
}

.squared-card .card-footer,
.card .card-footer {
  position: relative;
  max-height: 3em;
  line-height: 40px;
  padding-right: 80px;
  border-top: 1px solid #eff2f5;
  padding-left: 15px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.card.squared .card-footer {
  padding-right: 0 !important;
}

.squared-card .card-sport,
.card .card-sport {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.squared-card {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.2s cubic-bezier(0, 0.2, 0.4, 1);
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  display: block;
  border: none;
  border-radius: 0;
  margin: 0;
}

.squared-card .card-image > img {
  width: 100%;
  height: 100%;
}

.squared-card .card-header-label {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.92);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  color: #282c35;
  box-sizing: border-box;
  /*padding: 2px 10px;*/
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 10px;
}

.list-card,
.album-list-card {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.2s cubic-bezier(0, 0.2, 0.4, 1);
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  display: block;
}

.list-card.hover,
.album-list-card.hover {
  background-color: #f5f5f5;
}

.list-card .list-card-container {
  display: block;
  padding: 0;
}

.list-card .list-card-container > :first-child {
  margin-right: 0;
}

.list-card .list-card-container > :first-child {
  float: left;
  margin-right: 10px;
  overflow: visible;
}

.list-card .list-card-header {
  position: relative;
  width: 200px;
}

.list-card .list-card-header-squared {
  position: relative;
}

.list-card .list-card-header-squared .list-card-image {
  /* height: 100px !important; */
}

.list-card .list-card-content {
  height: 100px;
  padding: 0 20px;
  border-bottom: 1px solid #eff2f5;
}

.list-card .list-card-title {
  display: block;
  display: -webkit-box;
  max-height: 40px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #282c35;
  font-size: 15px;
  line-height: 20px;
  max-height: 2.5em;
  font-weight: 600;
  letter-spacing: 0;
  padding: 0;
  height: 40px;
  margin-bottom: 2px;
}

.list-card .list-card-image {
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  transition: background-color 0.5s;
  overflow: hidden;
  text-align: center;
  height: 100px;
}

.list-card .list-card-image img {
  max-width: 100%;
  max-height: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.list-card .list-card-label,
.album-list-card .list-card-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  color: #282c35;
  box-sizing: border-box;
  padding: 2px 10px;
  display: block;
  height: 37px;
  line-height: 37px;
}

.list-card .list-card-label.price {
  font-size: 17px;
  padding: 0 17px;
  color: #205173;
}

.list-card .list-card-sports.empty-label,
.card .card-footer.empty-label {
  margin-left: 10px;
}

.list-card .list-card-sports,
.album-list-card .list-card-sports {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 20px;
}

@media screen and (min-width: 480px) {
  .list-card .list-card-sports {
    padding-left: 0px;
  }
}

.list-card .list-card-actions,
.album-list-card .list-card-actions {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  line-height: 38px;
}

.list-card .list-card-actions a,
.list-card .list-card-actions button {
  display: inline-block;
  color: #666a73;
  font-size: 20px;
  width: 40px;
  height: 36px;
  border-left: 1px solid #eff2f5;
  box-sizing: border-box;
  text-align: center;
}

.list-card .list-card-actions i {
  position: relative;
  left: 1px;
}

.list-card .list-card-footer {
  position: relative;
  max-height: 3em;
  line-height: 36px;
  padding-right: 72px;
}

.list-card .list-card-title,
.album-list-card .list-card-title {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #282c35;
  font-size: 15px;
  line-height: 19px;
  max-height: 3em;
  letter-spacing: 0;
  padding: 0;
  height: 3em;
}

.list-card .list-card-date,
.album-list-card .list-card-date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #45494e;
  font-size: 12px;
  line-height: 18px;
  height: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 0 0;
}

.list-card .list-card-location,
.album-list-card .list-card-location {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #666a73;
  font-size: 12px;
  line-height: 18px;
  height: 2em;
  padding: 5px 0 0;
}

/** ALBUM **/

.album-main-row {
  margin-right: -6px;
  margin-left: -6px;
}

.album-list-wrapper {
  padding: 0 6px;
}

.album-list-card .list-card-container {
  display: block;
  padding: 0;
}

.album-list-card .albums-container.albums-4 {
  margin: 0 -6px;
}

.album-list-card .albums-container.albums-1 .list-card-header:first-child,
.album-list-card .albums-container.albums-2 .list-card-header:first-child {
  padding-left: 0;
}

.album-list-card .albums-container.albums-1 .list-card-header:last-child,
.album-list-card .albums-container.albums-2 .list-card-header:last-child {
  padding-right: 0;
}

.album-list-card .albums-container.albums-3 .list-card-header:first-child {
  padding-right: 8px;
  padding-left: 0;
}

.album-list-card .albums-container.albums-3 .list-card-header:last-child {
  padding-left: 8px;
  padding-right: 0;
}

/*.album-list-card .albums-container.albums-3 .list-card-header:nth-child(1) {

}*/

.album-list-card .albums-container.albums-3 .list-card-header:nth-child(2) {
  padding-left: 4px;
  padding-right: 4px;
}

.album-list-card .list-card-header {
  display: inline-block;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.album-list-card .list-card-header.album-1 {
  padding-left: 0;
  padding-right: 0;
}

.album-list-card .list-card-image {
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  transition: background-color 0.5s;
  overflow: hidden;
  text-align: center;
}

.album-list-card .list-card-image img {
  display: block;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.album-list-card .list-card-content {
  height: 120px;
  padding: 0 20px;
  border-bottom: 1px solid #eff2f5;
  clear: both;
}

.album-list-card .list-card-footer {
  position: relative;
  max-height: 3em;
  line-height: 36px;
  padding-right: 72px;
}

.tickets-list .list-card-subtitle {
  margin-left: 20px;
}

/** PANEL ITEM**/

.panel-item {
  border-left: 6px solid #e6e6e6;
}

.panel-item > .title {
  color: #205173;
  font-weight: bold;
}

.success-color,
.has-success {
  color: #3c763d;
}
.error-color,
.has-error {
  color: #a94442;
}

/*** TICKET CONTAINER ***/
.ticket-container {
  min-height: 200px;
}

.background-white {
  background-color: #fff;
}

/*** Homepage ***/

.claim-wrapper {
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0.9) 0%, white 25%);
  background: linear-gradient(rgba(255, 255, 255, 0.9) 0%, white 25%);
  overflow: visible;
  padding: 15px;
  text-align: center;
  margin-top: -110px;
  position: relative;
}

.small-separator {
  clear: both;
  display: block;
  width: 100%;
  height: 10px;
  background: transparent;
  margin: 10px 0;
  border-bottom: 1px solid #d6d6d6;
}

.mh140 {
  min-height: 140px;
}

/*** End Homepage ***/

/*** Media Queries ***/
.main-content .sticky {
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 1080px) {
  .main-content {
    padding: 0 30px;
  }
  .main-content .sticky {
    position: relative;
    top: unset;
  }

  .slider-container.home-slider {
    height: 60vw;
  }

  .slider-container.home-slider .slider-image-container {
    height: 60vw;
  }
}

@media only screen and (max-width: 768px) {
  .slider-container {
    /*height: 40vw;*/
  }
  .slider-container.home-slider {
    height: 90vw;
  }

  .slider-container.home-slider .slider-image-container {
    height: 100vw;
  }

  .claim-wrapper {
    background-color: #fff;
    overflow: visible;
    padding: 15px;
    text-align: center;
    margin-top: -110px;
    position: relative;
  }
}

@media only screen and (max-width: 480px) {
  .search-container {
    margin-top: 0;
  }

  .main-content {
    padding: 0 0;
  }

  .list-card.competition-list-card .list-card-header {
    float: none !important;
    margin-right: 0;
    width: 100%;
  }

  .list-card.competition-list-card .list-card-image {
    width: 100%;
    height: 100%;
  }

  .list-card.competition-list-card .list-card-image img {
    width: 100%;
    height: 100%;
    transform: none;
  }

  .claim-wrapper {
    margin-top: 0px;
  }

  .xs-d-block {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .mb-sm-5 {
    margin-bottom: 2rem;
  }
}
