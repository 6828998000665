.list-card {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: box-shadow 0.2s cubic-bezier(0, 0.2, 0.4, 1);
    background-color: #fff;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
    display: block;
}

.list-card.order-totals {
    padding: 10px;
}

.list-card.order-totals .totals {
    font-size: 17px;
    padding: 15px;
}

.list-card.hover,
.album-list-card.hover {
    background-color: #f5f5f5;
}

.list-card .list-card-container {
    display: block;
    padding: 0;
    /*width:70%;
    float: left;*/
}

/*.list-card .list-card-container:after{
    content: "";
    display: table;
    clear: both;
}*/

.list-card .list-card-container > :first-child {
    margin-right: 0;
}

.list-card .list-card-container > :first-child {
    float: left;
    margin-right: 10px;
    overflow: visible;
}

.list-card .list-card-header {
    position: relative;
    width: 200px;
}

.list-card .list-card-content {
    height: 100px;
    padding: 0 20px;
    border-bottom: 1px solid #eff2f5;
}

.list-card .list-card-title {
    display: block;
    display: -webkit-box;
    max-height: 40px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #205173;
    font-size: 15px;
    line-height: 20px;
    max-height: 2.5em;
    letter-spacing: 0;
    padding: 0;
    height: 40px;
    margin-bottom: 2px;
}

.list-card .list-card-image {
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    transition: background-color 0.5s;
    overflow: hidden;
    text-align: center;
    height: 100px;
}

.list-card .list-card-image img {
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    top: 50%;
}

.list-card .list-card-label,
.album-list-card .list-card-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.92);
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    color: #282c35;
    box-sizing: border-box;
    padding: 2px 10px;
    background-color: #eff2f5;
    display: block;
    height: 37px;
}

.list-card .list-card-label.price {
    font-size: 17px;
    padding: 10px;
    color: #205173;
}

.list-card .list-card-sports {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.list-card .list-card-actions,
.album-list-card .list-card-actions {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    line-height: 38px;
}

.list-card .list-card-actions a,
.list-card .list-card-actions button {
    display: inline-block;
    color: #666a73;
    font-size: 20px;
    width: 40px;
    height: 36px;
    border-left: 1px solid #eff2f5;
    box-sizing: border-box;
    text-align: center;
}

.list-card .list-card-actions button.team-button {
    display: inline-block;
    color: #205173;
    font-size: 20px;
    width: inherit;
    height: inherit;    
    border-color: #12324d !important;
    box-sizing: border-box;
    text-align: center;
    border-radius: 0;
}

.list-card .list-card-actions .info {
    display: inline-block;
    color: #666a73;
    font-size: 16px;
    width: 200px;
    height: 36px;
    border-left: 1px solid #eff2f5;
    box-sizing: border-box;
    text-align: center;
}

.list-card .list-card-actions i {
    position: relative;
    left: 1px;
}

.list-card .list-card-footer {
    position: relative;
    max-height: 3em;
    line-height: 36px;
    padding-right: 72px;
}

.list-card .list-card-footer-subscription {
    position: relative;
    max-height: 3em;
    line-height: 36px;
}

.list-card .list-card-date,
.album-list-card .list-card-date {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    color: #45494e;
    font-size: 12px;
    line-height: 18px;
    height: 2em;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 0 0;
}

.list-card .list-card-location,
.album-list-card .list-card-location {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #666a73;
    font-size: 12px;
    line-height: 18px;
    height: 2em;
    padding: 5px 0 0;
}

.list-card .list-card-footer-subscription .list-card-actions a,
.list-card .list-card-actions button {
    display: inline-block;
    color: #205173;
}

.list-card .list-card-content .limit-block {
    float: right;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 5px 5px;
    font-size: 14px;
    background-color: #205173;
    color: #fff;
    margin-top: 0px;
}

@media only screen and (max-width: 480px) {
    .list-card .list-card-content .info-block {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .list-card .list-card-content .limit-block {
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

@media only screen and (max-width: 480px) {
    .race-card-list .list-card .list-card-container .list-card-header {
        float: none !important;
        margin-right: 0;
        width: 100% !important;
    }

    .race-card-list .list-card .list-card-container .list-card-image {
        width: 100%;
        height: 100% !important;
    }

    .race-card-list .list-card .list-card-container .list-card-image img {
        width: 100%;
        height: 100%;
        transform: none !important;
    }

    .race-card-list .list-card .list-card-footer-subscription {
        padding-left: 10px;
    }

    .race-card-list .list-card .list-card-sports {
        padding-left: 8px;
        font-size: 12px;
    }
}
