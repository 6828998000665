.gallery-wrapper {
    margin: 10px auto;
    overflow: hidden;
}

.gallery-list {
    padding-left: 0;
    list-style: none;
}

.image-modal-controls{
    text-align: center;
}
.image-modal-controls > a{
    margin: 0 10px;
    font-size: 20px;  
}

.gallery-wrapper li {
    float: left;
    width: 25%;
    /* height: 115px; */
    padding: 0px;
    font-size: 10px;
    line-height: 1.4;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #fff;
}

@media screen and (max-width: 768px) {
    .gallery-wrapper li {
        float: left;
        width: 50%;
        /* height: 115px; */
        padding: 0px;
        font-size: 10px;
        line-height: 1.4;
        text-align: center;
        background-color: #f9f9f9;
        border: 1px solid #fff;
    }

    .gallery-wrapper .img-responsive {
        width: 100%;
    }
}